<template>
  <section id="cross-selling-alternative-products">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="4" md="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchAValue" placeholder="Search..." @keyup.enter="searchAProduct" />
          </b-input-group>
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedBrand" placeholder="Select brand" label="title" :options="allBrands"
            @input="changeSelectedBrand" />
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedCategory" placeholder="Select category" label="category" :options="allCategories"
            @input="changeSelectedCategory" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            A-Products
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-a-products" />
          </h4>
          <b-popover target="popover-a-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer" @click="exportTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="AProductsTable.currentPage"
          :items="AProductsTable.items" :fields="AProductsTable.fields" :sort-by.sync="AProductsTable.sortBy"
          :sort-desc.sync="AProductsTable.sortDesc" :sort-direction="AProductsTable.sortDirection"
          :filter="AProductsTable.filter" :filter-included-fields="AProductsTable.filterOn" @sort-changed="sortChanged"
          @row-clicked="AproductDetailsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="AProductsTable.currentPage" :total-rows="AProductsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleAProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="product-details-modal" :title="selectedProductSKU" size="xl" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetProductDetailsModal">
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Alternative Products
              </h4>
              <b-card-text class="font-medium-5 mb-0">
                <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                  @click="exportAlternativeProductsTable" />
              </b-card-text>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :per-page="10"
              :current-page="alternativeProductsTable.currentPage" :items="alternativeProductsTable.items"
              :fields="alternativeProductsTable.fields" :sort-by.sync="alternativeProductsTable.sortBy"
              :sort-desc.sync="alternativeProductsTable.sortDesc"
              :sort-direction="alternativeProductsTable.sortDirection" :filter="alternativeProductsTable.filter"
              :filter-included-fields="alternativeProductsTable.filterOn">
              <template #cell(delete)="data">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
                  @click="openDeleteCrossSellingAlternativeProductConfirm(data.item.xsell_id)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="alternativeProductsTable.currentPage"
                  :total-rows="alternativeProductsTable.totalRows" first-number last-number prev-class="prev-item"
                  next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Cross Selling Products
              </h4>
              <b-card-text class="font-medium-5 mb-0">
                <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                  @click="exportCrossSellingProductsTable" />
              </b-card-text>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :per-page="10"
              :current-page="crossSellingProductsTable.currentPage" :items="crossSellingProductsTable.items"
              :fields="crossSellingProductsTable.fields" :sort-by.sync="crossSellingProductsTable.sortBy"
              :sort-desc.sync="crossSellingProductsTable.sortDesc"
              :sort-direction="crossSellingProductsTable.sortDirection" :filter="crossSellingProductsTable.filter"
              :filter-included-fields="crossSellingProductsTable.filterOn">
              <template #cell(delete)="data">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
                  @click="openDeleteCrossSellingAlternativeProductConfirm(data.item.xsell_id)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="crossSellingProductsTable.currentPage"
                  :total-rows="crossSellingProductsTable.totalRows" first-number last-number prev-class="prev-item"
                  next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <hr>
      <b-overlay :show="showProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Add Products
            </h4>
            <b-card-text class="font-medium-5 mb-0 w-50">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input v-model="searchValue" placeholder="Search..." @keyup.enter="searchProduct" />
              </b-input-group>
            </b-card-text>
          </b-card-header>
          <b-table striped hover responsive class="position-relative" :current-page="productsTable.currentPage"
            :items="productsTable.items" :fields="productsTable.fields" :sort-by.sync="productsTable.sortBy"
            :sort-desc.sync="productsTable.sortDesc" :sort-direction="productsTable.sortDirection"
            :filter="productsTable.filter" :filter-included-fields="productsTable.filterOn"
            @row-clicked="productDetailsRowClicked">
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="productsTable.currentPage" :total-rows="productsTable.totalRows" first-number
                last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                @change="handleProductsTablePageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-modal>
    <b-modal ref="add-product-modal" title="Add Product" size="sm" hide-footer centered>
      <div class="d-flex justify-content-center">
        <p>Where do you want to add the product?</p>
      </div>
      <div class="d-flex justify-content-around mt-3">
        <b-button variant="info" @click="addToCrossSellingAlternative(9)">Alternative</b-button>
        <b-button variant="success" @click="addToCrossSellingAlternative(3)">Cross Selling</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal,
  BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import axios from 'axios';
import * as XLSX from 'xlsx';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    BButton,
    flatPickr,
    ToastificationContent,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      showProductDetails: true,
      showProducts: true,
      showExportStart: false,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchAValue: '',
      searchValue: '',
      selectedProductID: '',
      selectedProductSKU: '',
      selectedCSAProductID: '',
      allBrands: [],
      selectedBrand: '',
      selectedCategory: '',
      allCategories: [],
      queryParams: {},
      queryParamsCrossSelling: {},
      queryParamsAlternative: {},
      queryParamsProducts: {},
      AProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'manufacturer', label: 'manufacturer', sortable: true },
          { key: 'category', label: 'category', sortable: true },
          { key: 'qty_sold', label: 'qty sold', sortable: true },
          {
            key: 'qty_sold_curr_year',
            label: 'qty sold current year',
            sortable: true,
          },
          {
            key: 'avg_pprice',
            label: 'avg. purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_sprice',
            label: 'avg. sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_curr_year',
            label: 'turnover current year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          { key: 'orders_count', label: 'orders count', sortable: true },
          {
            key: 'share_total_sales',
            label: 'share of total sales',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'min_stock', label: 'min stock level', sortable: true },
          {
            key: 'last_order_date',
            label: 'last order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'alternative_items_count', label: 'alternatives', sortable: true },
          { key: 'cross_selling_items_count', label: 'cross selling', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      crossSellingProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'price',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      alternativeProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'price',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'pprice',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'pprice',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getAllBrands();
      await this.getAllCategories();
      await this.getAProducts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllBrands() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-manufacturers/`, {});
        this.allBrands = response.data.results.map(item => item.value);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getAllCategories() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-categories/`, {});
        this.allCategories = response.data.results.map(item => item.category);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getAProducts() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/cross-selling-alternative-products/`, this.queryParams);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.AProductsTable.items = results;
          this.AProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.AProductsTable.items = [];
          this.AProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getProductDetails() {
      this.showProductDetails = true;
      try {
        const responseCrossSelling = await this.makeRequest(
          `${process.env.VUE_APP_ROOT_API}/cross-selling-products/`,
          this.queryParamsCrossSelling,
        );
        const responseAlternative = await this.makeRequest(
          `${process.env.VUE_APP_ROOT_API}/alternative-products/`,
          this.queryParamsAlternative,
        );

        const resultsCrossSelling = responseCrossSelling.data.results;
        if (Array.isArray(resultsCrossSelling) && resultsCrossSelling.length > 0) {
          this.crossSellingProductsTable.items = resultsCrossSelling;
          this.crossSellingProductsTable.totalRows = resultsCrossSelling.length * 2;
        }
        else {
          this.crossSellingProductsTable.items = [];
          this.crossSellingProductsTable.totalRows = 0;
        }

        const resultsAlternative = responseAlternative.data.results;
        if (Array.isArray(resultsAlternative) && resultsAlternative.length > 0) {
          this.alternativeProductsTable.items = resultsAlternative;
          this.alternativeProductsTable.totalRows = resultsAlternative.length * 2;
        }
        else {
          this.alternativeProductsTable.items = [];
          this.alternativeProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.responseCrossSelling && error.responseCrossSelling.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductDetails = false;
      }
    },
    async getProducts() {
      this.showProducts = true;
      try {
        this.queryParamsProducts.except_id = this.selectedProductID;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-except/`, this.queryParamsProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsTable.items = results;
          this.productsTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsTable.items = [];
          this.productsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProducts = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];

        await this.getAProducts();

        this.oldDateRange = this.dateRange;
      }
    },
    async searchAProduct() {
      this.queryParams.page = 1;
      this.queryParams.search = '%' + this.searchAValue.toLowerCase() + '%';
      await this.getAProducts();
    },
    async searchProduct() {
      let searchValue = this.searchValue.toLowerCase().replace(/-/g, "").replace(/ /g, "");
      this.queryParamsProducts.page = 1;
      this.queryParamsProducts.search = '%' + searchValue + '%';
      await this.getProducts();
    },
    async changeSelectedBrand() {
      this.queryParams.brand = this.selectedBrand;
      await this.getAProducts();
    },
    async changeSelectedCategory() {
      this.queryParams.category = this.selectedCategory;
      await this.getAProducts();
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParams.ordering = `-${value.sortBy}`;
      } else {
        this.queryParams.ordering = value.sortBy;
      }
      await this.getAProducts();
    },
    async handleAProductsTablePageChange(value) {
      this.queryParams.page = value;
      await this.getAProducts();
    },
    async handleProductsTablePageChange(value) {
      this.queryParamsProducts.page = value;
      await this.getProducts();
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-cross-selling-alternative-products/`, this.queryParams);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'AProducts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportAlternativeProductsTable() {
      try {
        const exportedData = this.alternativeProductsTable.items;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'AlternativeProducts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportCrossSellingProductsTable() {
      try {
        const exportedData = this.crossSellingProductsTable.items;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'CrossSellingProducts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async AproductDetailsRowClicked(row) {
      this.selectedProductID = row.id;
      this.selectedProductSKU = row.sku;

      this.queryParamsCrossSelling.id = this.selectedProductID;
      this.queryParamsAlternative.id = this.selectedProductID;

      await this.getProductDetails();
      await this.getProducts();

      this.$refs['product-details-modal'].show();
    },
    productDetailsRowClicked(row) {
      this.selectedCSAProductID = row.id;
      this.$refs['add-product-modal'].show();
    },
    async addToCrossSellingAlternative(csaGroupID) {
      try {
        await axios.post(`${process.env.VUE_APP_ROOT_API}/add-cross-selling-alternative-product/`, {
          product_id: this.selectedProductID,
          csa_product_id: this.selectedCSAProductID,
          csa_group_id: csaGroupID,
        }, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        }).then(async () => {
          await this.getProductDetails();
          await this.getProducts();
          this.showToast('success', "Product has been added");
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          this.showToast('danger', error);
        }
      }
      // Close the modal
      this.$refs['add-product-modal'].hide();
    },
    resetProductDetailsModal() {
      this.selectedProductID = '';
      this.selectedProductSKU = '';
      this.queryParamsProducts = {};
      this.searchValue = '';
    },
    openDeleteCrossSellingAlternativeProductConfirm(xsell_id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          // Make the API call to delete the item
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/delete-cross-selling-alternative-product/${xsell_id}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'Item has been deleted');
              await this.getProductDetails();
              await this.getProducts();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>